<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 11:46:23
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-07-19 16:08:54
-->
<template>
    <div :class="['register-page', $i18n.locale]">
        <div class="navbar">
            <el-dropdown placement="bottom" class="custom-dropdown" @command="onCommand" trigger="click">
                <span class="el-dropdown-language">{{ currentLanguage }} </span>
                <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                    <el-dropdown-item :command="item.label" v-for="(item, index) in languages" :key="index"
                        :disabled="item.label === currentLanguage">{{ item.label }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="header">
            <h2>{{ $t('login.loginAndRegister') }}</h2>
            <p>{{ $t('login.title') }}</p>
            <!-- <p class="tips">{{ $t('login.btn.title') }}</p> -->
        </div>
        <!-- 登录选择 -->
        <div class="login">
            <div @click="selectMobile">
                <span :class="[ qkStatus ? loginStatus?'mobile-qk1':'mobile-qk':{ 'mobile-login': loginStatus}]">{{ $t('login.mobileLogin') }}</span>
            </div>
            <span class="icon">|</span>
            <div @click="selectEmail">
                <span :class="[ qkStatus ? !loginStatus?'mobile-qk1':'mobile-qk':{ 'mobile-login': !loginStatus}]">{{ $t('login.emailLogin') }}</span>
            </div>
        </div>
        <!-- 手机号绑定页面 -->
        <div class="content" v-if="loginStatus">
            <div class="form">
                <div class="item">
                    <label for="mobile" @click="selectAreaCode">
                        <div class="area">
                            <span class="no">{{ areaCode }}</span>
                            <i class="arrow_bottom"></i>
                        </div>
                    </label>
                    <div class="info">
                        <van-field autocomplete="off" @blur="handleBlurReset($event)" @focus="handleFouceReset($event)"
                            label="" name="mobile" type="digit" v-model.trim="form.phone"
                            :placeholder="$t('login.formList[0].label')" :border="false" />
                    </div>
                </div>
                <div class="item">
                    <label class="codeLabel codeLabel-item" for="verifyCode">{{ $t('login.formList[1].label') }}</label>
                    <div class="info">
                        <div class="placeholder_code" v-if="!isFocus && $i18n.locale === 'ja-JP'" @click="onPlaceholder">
                            {{ $t('login.formList[1].placeholder') }}
                        </div>
                        <input
                            :style="$i18n.locale === 'zh-CN' ? 'padding-left: 50px' : ($i18n.locale === 'es-ES' || $i18n.locale === 'it-IT' || $i18n.locale === 'tr-TR') ? 'padding-left: 10px' : 'padding-left: 20px'"
                            autocomplete="off" ref="verifyCode" v-else :disabled="isCodeInputDisabled" v-model="form.code"
                            @blur="handleBlurReset($event)" @focus="handleFouceReset($event)" @input="validatorCode"
                            type="number" label=""
                            :placeholder="$i18n.locale === 'ja-JP' ? '' : $t('login.formList[1].placeholder')"
                            name="verifyCode" :border="false" />
                        <span :class="{ 'is-disabled': isCodeBtnDisabled }" @click="handleSendCode" class="get-code-btn">{{
                            codeText }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 邮箱登录页面 -->
        <div class="content" v-else>
            <div class="form">
                <div class="item">
                    <label for="email" v-if="$i18n.locale === 'tr-TR' || $i18n.locale === 'ar-AR'">{{ $t('login.email') }}</label>
                    <label for="email" v-else>E-mail</label>
                    <div class="info">
                        <van-field @blur="handleBlurReset($event)" @focus="handleFouceReset($event)"
                            v-model.trim="form.email" name="" label="" :placeholder="$t('login.formList[2].label')"  class="iteminput"/>
                    </div>
                </div>
                <div class="item">
                    <label for="verifyCode" class="codeLabel codeLabel-item">{{ $t('login.formList[1].label') }}</label>
                    <div class="info">
                        <div class="placeholder_code" v-if="!isFocus && $i18n.locale === 'ja-JP'" @click="onPlaceholder">
                            {{ $t('login.formList[1].placeholder') }}
                        </div>
                        <input
                            :style="$i18n.locale === 'zh-CN' ? 'padding-left: 50px' : ($i18n.locale === 'es-ES' || $i18n.locale === 'it-IT' || $i18n.locale === 'tr-TR') ? 'padding-left: 10px' : 'padding-left: 20px'"
                            autocomplete="off" ref="verifyCode" v-else :disabled="isCodeInputDisabled" v-model="form.code"
                            @blur="handleBlurReset($event)" @focus="handleFouceReset($event)" @input="validatorCode"
                            type="number" label=""
                            :placeholder="$i18n.locale === 'ja-JP' ? '' : $t('login.formList[1].placeholder')"
                            name="verifyCode" :border="false" class="itemyzm"/>
                        <span :class="{ 'is-disabled': isCodeBtnDisabledEmail }" @click="handleSendCode"
                            class="get-code-btn">{{ codeText }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="footer">
            <div class="rule" @click="isAutoLogin = !isAutoLogin">
                <span :class="[{ 'is-check': isAutoLogin }, 'checkbox']">
                    <input type="checkbox" v-model="isAutoLogin" />
                </span>
                <i>{{ $t('login.autoLogin') }}</i>
            </div>

            <div class="submit-btn">
            <div class="error-tips" v-if="erorTips">{{ erorTips }}</div>
            <van-button :disabled="isMobileSubmitDisabled" :class="{
                'is-disabled': isMobileSubmitDisabled,
                'custom-btn': true,
            }" @click.prevent="handleMobileSumbit" @keyup.enter="handleMobileSumbit" type="primary"
                    color="linear-gradient(90deg, #00E3C9 0%, #009FE8 100%)">{{ $t('login.registerAndLogin') }}</van-button>
                <!-- <div class="create-account" @click="toRegister">
                                                                                                                                                                                                                                                {{ $t('login.btn.submit') }}
                                                                                                                                                                                                                                            </div> -->
            </div>
        </div>
        <!-- <p class="tips">{{ $t('login.btn.title') }}</p> -->
        <area-code v-if="showAreaCodeSelect" @selectCode="onSelect" class="areaCode" />
    </div>
</template>
<script>
import {
    smsLogin,
    sendValidationCode,
    sendValidationEmail,
    verifyNewUser
} from '@/assets/js/apolloGql.js'
import { shareTime } from '@/assets/js/config.js'
import AreaCode from '@/components/user/AreaCode.vue'
import { languageConfig } from '@/assets/js/langObject'
export default {
    components: {
        AreaCode
    },
    data() {
        return {
            isFocus: false,
            erorTips: '',
            iphoneResult: '',
            isAutoLogin: false,
            areaCode: '',
            showAreaCodeSelect: false,
            currentLanguage: '', 
            languages: languageConfig,
            form: {
                code: '',
                phone: '', 
                email: ''
            },
            height: {
                isValid: false,
                value: '',
            },
            birthday: {
                isValid: false,
                year: '',
                month: '',
                day: '',
            },
            // 验证码定时器
            timer: '',
            // 获取验证码时间间隔 60s
            countDown: 60,
            codeText: '',
            // 验证码默认不能输入 验证码发送成功后才能输入
            isCodeInputDisabled: false,
            // 验证码是否正在发送状态
            isCodeSending: false,
            outTimeStamp: 0,
            // 错误信息
            errorMg: '-',
            // 提交按钮状态
            submitDisabled: false,
            // 按钮开关
            isBtnHand: true,
            scanType: null,
            loginInfo: JSON.parse(window.localStorage.getItem('loginInfo')),
            // 选择登录状态
            loginStatus: true,
            timeTag: null,
            // 倒计时开始时间
            countDownTime: 0,
            qkStatus: false,
            // 登录按钮是否正在加载中
            loginBtnState: false
        }
    },

    computed: {
        // 是否禁用获取验证码按钮 手机号长度不对|正在倒计时|验证码已发送等待返回
        isCodeBtnDisabled() {
            return !this.form.phone || this.form.phone.length < 5 || this.form.phone.length > 15 || this.isCodeSending
        },
        isCodeBtnDisabledEmail() {
            return !this.form.email || this.isCodeSending
        },
        // 是否禁用登录按钮
        isMobileSubmitDisabled() {
            return this.form.code.length !== 4
        },
        // 是否禁用用户信息注册按钮
        isSubmitDisabled() {
            return this.birthday.isValid === false || this.height.isValid === false
        },
        userAgent() {
            const ua = navigator.userAgent
            return {
                ua: ua,
                isAndroid: ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1,
                isiOS: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            }
        },
    },

    watch: {
        // <p>您的验证码为<%=code%>，欢迎使用维塑三维体测精灵，请在10分钟内输入。</p>
        // <p>Il tuo codice di verifica è <%=code%>, il codice è valido per 10 minuti. Goditi la tua esperienza di scansione corporea 3D Visbody!</p>
    },

    beforeDestroy() {
        clearInterval(this.timer)
    },
    created() {
        // 分享链接处理
        let url = window.location.href
        const arrUrl = url.split('&')
        localStorage.setItem('share', true)
        if(arrUrl.length>3){
            sessionStorage.clear();
            localStorage.clear()
           // 7天分享链接为有
            let index = url.includes('type') ? 1: 0
            const flag = Date.now() - arrUrl[3 + index].split('-')[0]
            
            if(flag < (parseInt(shareTime) * 60 * 60 * 1000) ){
                // console.log(arrUrl[0].split('?')[1], 88888, url.includes('type'))
                sessionStorage.setItem('token', index ? arrUrl[1] :arrUrl[0].split('?')[1])
                localStorage.setItem('unit', arrUrl[1+index])
                localStorage.setItem('lang', arrUrl[2+index])
                localStorage.setItem('deviceType', arrUrl[3+index].split('-')[1])
                localStorage.setItem('share', false)
                this.$i18n.locale = arrUrl[2+ index]
                // 历史分享scanId
                // console.log(arrUrl[5])
                if(index)localStorage.setItem('shareScanId', arrUrl[5])

                this.$router.push({
                    path : url.includes('type') ? '/report?type=1': '/report'
                });
            }
        }
        var userAgent = navigator.userAgent;
        console.log('heihei',userAgent);
        if(userAgent.indexOf('Quark')>-1){
            this.qkStatus = true
        }
        // 单位默认值为公制单位
        const unit = localStorage.getItem('unit')
        if(!unit){
            localStorage.setItem('unit', 'metric')
        }
        // 默认语言配置
        const lang = localStorage.getItem('lang')
        if(!lang){
            localStorage.setItem('lang', 'en-US')
        }
        
        console.log(`语言配置${JSON.stringify(languageConfig)}`)
        this.codeText = this.$t('login.formList[0].placeholder')
        this.areaCode = this.languages.find((item) => item.value === this.$i18n.locale)?.no
        this.currentLanguage = this.languages.find((item) => item.value === this.$i18n.locale)?.label
        const register = this.$route.query.register
        if (register) {
            this.toRegister();
        }
    },
    mounted() {
        // 保留信息不被清空
        const lookUserInfo = JSON.parse(window.localStorage.getItem('lookUserInfo'))
        if (lookUserInfo) {
            this.form.phone = lookUserInfo.phone
            this.form.code = lookUserInfo.code
            this.form.email = lookUserInfo.email
            this.countDownTime = lookUserInfo.countDownTime || 0
            this.isCodeInputDisabled = false
            const time = new Date().getTime() - this.countDownTime
            if (time < 60000) {
                this.isCodeSending = true
                this.countDown = 60 - Math.floor(time / 1000)
                this.countDownAction()
            }
        }
    },

    methods: {
        selectMobile() {
            this.form.code = ''
            this.clearTimer()
            this.loginStatus = true
            if (this.loginStatus) {
                this.form.email = ''
            } else {
                this.form.phone = ''
            }
            this.erorTips = ''
        },
        selectEmail() {
            this.form.code = ''
            this.clearTimer()
            if (this.form.phone) {
                this.form.phone = ''
            }
            this.loginStatus = false
        },
        // 清空验证码
        clearTimer() {
            this.isCodeSending = false
            this.codeText = this.$t('login.formList[0].placeholder')
            clearInterval(this.timeTag)
            this.countDown = 60
        },
        selectAreaCode() {
            this.showAreaCodeSelect = true
        },
        onSelect(code) {
            if (code) this.areaCode = code
            this.showAreaCodeSelect = false
        },
        onPlaceholder() {
            this.isFocus = true
            this.$nextTick(() => {
                console.log(this.$refs.verifyCode)
                this.$refs.verifyCode.focus()
            })
        },
        onCommand(lang) {
            // console.log(lang);
            // console.log(this.languages);
            let languageInfo = {}
            for (let index = 0; index < this.languages.length; index++) {
                if (lang === this.languages[index].label) {
                    languageInfo = this.languages[index]
                    // console.log(languageInfo);
                    break
                }
            }
            this.$i18n.locale = languageInfo.value
            this.areaCode = languageInfo?.no
            localStorage.setItem('lang', this.$i18n.locale)
            this.currentLanguage = lang
            this.codeText = this.$t('login.formList[0].placeholder')
            this.erorTips = ''
            this.form.phone = ''
            this.form.code = ''
            this.form.email = ''
        },
        // 失去焦点后重置背景
        handleBlurReset(e) {
            const phone = this.form.phone
            const email = this.form.email
            if (phone === '' || email === '') {
                if (this.loginStatus && phone === '') {
                    this.$toast({
                        message: this.$t('login.formList[0].label'),
                        duration: 1500,
                    })
                    return
                } else if (!this.loginStatus && email === '') {
                    this.$toast({
                        message: this.$t('login.formList[2].label'),
                        duration: 1500,
                    })
                    return
                }
            }
            if (this.loginStatus) {
                if (phone.length < 5 || phone.length > 15) {
                    this.erorTips = this.$t('login.rule.phone.requiredMsg')
                } else {
                    this.erorTips = ''
                }
            } else {
                // eslint-disable-next-line no-useless-escape
                const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!email.test(this.form.email)) {
                    this.erorTips = this.$t('login.rule.email.requiredMsg')
                } else {
                    this.erorTips = ''
                }
            }

            if (this.form.code === '') {
                this.isFocus = false
            }
            this.outTimeStamp = e.timeStamp
            setTimeout(() => {
                if (this.outTimeStamp === e.timeStamp) {
                    this.resetBackground()
                }
            }, 100)
        },

        handleFouceReset(e) {
            this.outTimeStamp = e.timeStamp
        },

        // 重置iso下键盘收回后底部空白问题
        resetBackground() {
            window.scrollTo(0, 0)
        },
        async handleVerifyNewUser() {
            let mobile = ''
            if (this.form.phone) {
                mobile = this.areaCode + this.form.phone
            }
            const resData = await this.$apollo.query({
                query: verifyNewUser,
                fetchPolicy: 'network-only',
                variables: {
                    mobile: mobile,
                    email: this.form.email,
                }
            })
            const data = resData.data.verifyNewUser
            if (data.code === 404) {
                if (this.loginStatus) {
                    // 手机号未注册提示文案
                    this.erorTips = this.$t('login.rule.phone.patternMsg')
                } else {
                    // 邮箱未注册提示文案
                    this.erorTips = this.$t('login.rule.email.patternMsg')
                }
                return false
            }
            return true


        },
        // 倒计时
        countDownAction() {
            this.countDownTime = new Date().getTime()
            let that = this
            this.timeTag = setInterval(() => {
                that.countDown--
                that.codeText = `${that.countDown}${this.$t('login.retransmission')}`
                if (that.countDown === 0) {
                    that.isCodeSending = false
                    that.codeText = this.$t('login.formList[0].placeholder')
                    clearInterval(this.timeTag)
                    that.countDown = 60
                }
            }, 1000)
            this.timer = this.timeTag
        },
        // 发送验证码
        async handleSendCode() {
            let that = this
            // 判断按钮状态 禁用时不可点击
            if (this.isCodeBtnDisabled && this.loginStatus) {
                return
            } else if (this.isCodeBtnDisabledEmail && !this.loginStatus) {
                return
            }
            that.form.code = ''
            const reg = /^[0-9]*$/
            // eslint-disable-next-line no-useless-escape
            const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.countDown === 60) {
                if (!reg.test(this.form.phone) && this.loginStatus) {
                    this.erorTips = this.$t('login.rule.phone.requiredMsg')
                } else if (!email.test(this.form.email) && !this.loginStatus) {
                    this.erorTips = this.$t('login.rule.email.requiredMsg')
                } else {
                    // const flag = await this.handleVerifyNewUser()
                    // if (!flag) return this.jumpRegis();
                    this.isCodeSending = true
                    // 验证码发送成功后可输入验证码
                    this.isCodeInputDisabled = false
                    
                    if (this.loginStatus) {
                        let scanId = ''
                        if (this.$route.query.redirect) {
                            let index =  this.$route.query.redirect.indexOf('scan_id'); // 获取指定字符的位置
                            if (index !== -1) scanId = this.$route.query.redirect.substring(index + 8); // 截取指定字符后面的内容
                            console.log(1111111111, scanId)
                        }
                        this.$apollo
                            .mutate({
                                mutation: sendValidationCode,
                                variables: {
                                    mobile: this.areaCode + this.form.phone,
                                    scanId: scanId,
                                },
                            })
                            .then((res) => {
                                const data = res.data.sendValidationCode
                                if (data.code === 200) {
                                    this.countDownAction()
                                    this.$toast({
                                        message: this.$t('login.success'),
                                        duration: 1500,
                                    })
                                } else if (data.code === 404) {
                                    // 手机号未注册提示文案
                                    this.erorTips = this.$t('login.rule.phone.patternMsg')
                                } else {
                                    this.isCodeSending = false
                                    this.$toast({
                                        message: this.$t('login.error'),
                                        duration: 2000,
                                    })
                                }
                            })
                            .catch((err) => {
                                console.error(err)
                                this.isCodeSending = false
                            })
                    } else {
                        this.$apollo
                            .mutate({
                                mutation: sendValidationEmail,
                                variables: {
                                    email: this.form.email,
                                },
                            })
                            .then((res) => {
                                const data = res.data.sendValidationEmail
                                if (data.code === 200) {
                                    this.countDownAction()
                                    this.$toast({
                                        message: this.$t('login.success'),
                                        duration: 2000,
                                    })
                                } else if (data.code === 404) {
                                    // 邮箱未注册提示文案
                                    this.erorTips = this.$t('login.rule.email.patternMsg')
                                } else {
                                    this.isCodeSending = false
                                    this.$toast({
                                        message: this.$t('login.error'),
                                        duration: 1500,
                                    })
                                }
                            })
                            .catch((err) => {
                                console.error(err)
                                this.isCodeSending = false
                            })

                    }
                }
            }
        },
        // 跳转注册页面
        jumpRegis() {
            const setTimer = setTimeout(() => {
                this.toRegister()
                window.clearTimeout(setTimer)
            }, 1000 * 1);
        },
        // 清空手机号
        handleClearMobile() {
            if (this.form.phone) {
                this.form.phone = ''
                this.form.code = ''
                window.localStorage.removeItem('lookUserInfo')
            } else {
                this.form.email = ''
                this.form.code = ''
                window.localStorage.removeItem('lookUserInfo')
            }
        },
        validateForm() {    
            let err

            if (!this.form.code) {
                err = this.$t('login.formList[1].placeholder')
            }

            // 判断验证码少于4位
            if (this.form.code && this.form.code.length < 4) {
                err = this.$t('login.rule.authCode.requiredMsg')
            }
            if (!this.form.phone && !this.form.email) {
                if (this.loginStatus) {
                    err = this.$t('login.formList[0].label')
                } else {
                    err = this.$t('login.formList[2].label')
                }

            } else {
                if (this.loginStatus) {
                    const reg = /^[0-9]*$/
                    if (!reg.test(this.form.phone)) {
                        err = this.$t('login.rule.phone.requiredMsg')
                    }
                } else {
                    // eslint-disable-next-line no-useless-escape
                    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!email.test(this.form.email)) {
                        err = this.$t('login.rule.email.requiredMsg')
                    }
                }
            }
            if (err) {
                this.erorTips = err
                return false
            }
            return true
        },
        // 用户登录
        handleMobileSumbit() {
            if (!this.isMobileSubmitDisabled && this.validateForm() && !this.loginBtnState) {
                this.loginBtnState = true
                let mobile = ''
                if (this.form.phone) {
                    mobile = this.areaCode + this.form.phone
                }
                this.$apollo
                    .query({
                        query: smsLogin,
                        fetchPolicy: 'network-only',
                        variables: {
                            mobile: mobile,
                            email: this.form.email,
                            code: String(this.form.code) || 0,
                        },
                    })
                    .then((res) => {
                        const data = res.data.smsLogin
                        // 老用户 已有用户信息
                        if (data.code === 200) {
                            window.localStorage.removeItem('modelInfo')
                            window.localStorage.removeItem('lookUserInfo')
                            // 保存token
                            sessionStorage.setItem('token', data.data.token)
                            // 下次自动登录
                            if (this.isAutoLogin) {
                                // 设置自动登录
                                localStorage.setItem('token', data.data.token)
                            }
                            if (!this.loginStatus) {
                                localStorage.setItem('email', this.form.email)
                            } else {
                                localStorage.removeItem('email')
                            }
                            this.$toast({
                                message: this.$t('login.loginSuccess'),
                                duration: 1500,
                            })
                            setTimeout(() => {
                                this.loginBtnState = false
                                // 默认跳转到报告页面
                                let path = '/report';
                                // 如果是从别的页面跳转过来的，跳到之前的页面
                                if (this.$route.query.redirect) {
                                    path = this.$route.query.redirect   // 跳到之前的页面
                                }
                                this.$router.push({
                                    path: path
                                });
                            }, 2000)
                        } else if (data.code === 413) {
                            this.loginBtnState = false
                            this.erorTips = this.$t('login.rule.authCode.requiredMsg')
                        } else if (data.code === 404) {
                            this.loginBtnState = false
                            console.log(`当前手机号或邮箱未注册`)
                            this.toRegister()
                        } else {
                            this.loginBtnState = false
                            // 其他 登录失败
                            // this.erorTips = data.message
                            this.erorTips = this.$t('login.rule.phone.patternMsg')
                        }
                    })
                    .catch(() => {
                        this.loginBtnState = false
                    })
            }
        },

        // 判断验证码长度
        validatorCode() {
            const code = this.form.code.replace(/\s*/g, "")
            if (code.length > 4) {
                this.form.code = code.substring(0, 4)
                // 直接截取，不提示
                // this.$toast({
                //     message: this.$t('register.verify'),
                //     duration: 2000,
                // })
            }
        },
        // 去注册页面
        toRegister() {

            if (!this.form.phone && !this.form.email) {
                var err = ''
                if (!this.form.phone) {
                    err = this.$t('login.formList[0].label')
                } else {
                    err = this.$t('login.formList[2].label')
                }
                this.erorTips = err
                return
            }
            const userInfo = JSON.stringify({
                phone: this.form.phone,
                code: this.form.code,
                email: this.form.email,
                countDownTime: this.countDownTime,
                areaCode: this.areaCode,
            })
            window.localStorage.setItem('lookUserInfo', userInfo)
            console.log(`开始存储 ${userInfo}`)
            // const phone = this.areaCode + this.form.phone
            this.$router.push({
                path: '/register',
                query: { redirect: this.$route.query.redirect || '/exception/report' }  // 'to.fullPath'跳转到登录之前页面的路径
            });
        }
    },
}
</script>

<style scoped lang="less">
.register-page {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    background-image: linear-gradient(180deg, #262849, #07090f);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // justify-content: space-between;
    justify-content: initial;

    .areaCode {
        z-index: 999; 
    }

    .navbar {
        width: 100%;
        height: 30px;
        padding-right: 45px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .back {
            font-size: 12px;
            padding-left: 21px;
            color: #fff;
        }

        /deep/ .van-icon-arrow-left:before {
            font-size: 14px;
            color: #fff;
        }
    }

    .el-dropdown-language {
        font-size: 12px;
        color: #fff;

        &::after {
            content: '';
            position: absolute;
            top: 55%;
            right: -14px;
            transform: translateY(-80%);
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #fff;
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding-top: 60.5px;
        // padding-bottom: 38.5px;

        h2 {
            // width: 84px;
            height: 30px;
            mix-blend-mode: initial;
            color: #FFFFFF;
            // background-image: linear-gradient(to right, #00e3c9, #00a4e6), linear-gradient(#ffffff, #ffffff);
            font-family: PingFangSC;
            font-size: 21px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            -webkit-background-clip: text;
            background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        p {
            width: 168px;
            height: 34px;
            font-family: PingFangSC;
            mix-blend-mode: initial;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #8AA3BE;
            margin-top: 8px;
            margin-bottom: 58px;
        }
    }

    .login {
        display: flex;
        justify-content: space-between;
        width: 172px;
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #8AA3BE;
        line-height: 15px;
        margin-bottom: 43px;

        .icon {
            width: 1px;
            height: 19px;
            color: #373955;
        }

        .mobile-login {
            width: 60px;
            height: 15px;
            font-size: 15px;
            font-family: PingFangSC;
            font-weight: bold;
            color: #8AA3BE;
            line-height: 15px;
            padding-bottom: 15px;
            border-bottom: 2px solid #009FE8;
            background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .mobile-qk{
            width: 60px;
            height: 15px;
            font-size: 15px;
            font-family: PingFangSC;
            font-weight: bold;
            color: #8AA3BE;
            line-height: 15px;
            padding-bottom: 15px;
            // color:#009FE8!important;
        }
        .mobile-qk1{
            position: relative;
            color:#009FE8!important;
            &:before{
                content: ' ';
                display: inline-block ;
                position: absolute;
                top: 25px;
                left: 28%;
                width: 50%;
                border-bottom: 2px solid #009FE8;
            }
        }
    }

    /deep/ .content .form .item {
        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
            -webkit-text-fill-color: #FFFFFF !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }

    }

    .content {

        // flex: 1;
        // 表单
        .form {
            width: 100%;
            height: auto;

            .item {
                width: 345px;
                height: 56px;
                margin: 0 auto;
                border-radius: 4px;
                // background-color: #232440;
                background: #303C5C;
                padding: 0 16px;
                color: rgba(255, 255, 255, 1);
                margin-bottom: 8px;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.24);
                display: flex;
                align-items: center;
                box-sizing: border-box;

                .area {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .no {
                        margin-right: 7px;
                    }

                    .arrow_bottom {
                        width: 0;
                        height: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 6px solid #fff;
                    }
                }

                .info {
                    position: relative;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /deep/ .van-field {
                        padding-left: 0;
                        // background-color: #232440;
                        background: #303C5C;
                    }

                    .placeholder_code {
                        width: 180px;
                        font-size: 14px;
                        color: #6c6c6f;
                        text-align: left;
                        padding-left: 20px;
                        padding-right: 30px;
                    }

                    /deep/ .van-field__control {
                        color: #fff;
                        margin-left: 30px;
                        padding-left: 0px;
                    }

                    /deep/ .van-field__control::placeholder {
                        color: #6c6c6f;
                        font-size: 13.5px; 
                    }

                    &.center {
                        justify-content: center;
                    }

                    .over {
                        position: absolute;
                        z-index: 10;
                        width: 100%;
                        height: 56px;
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.5);
                        line-height: 56px;
                        // pointer-events: none;
                        background-color: #303C5C;
                    }
                }

                label {
                    font-size: 14px;
                    text-align: left;
                }

                input {
                    width: 100%;
                    height: 56px;
                    background: transparent;
                    // padding-left: 46px;
                    font-size: 14px;
                    color: #fff;

                    &[name='birthday'] {
                        flex: none;
                        width: 50px;
                    }
                }

                /deep/ .van-field__control {
                    margin-left: 46px;
                }

                input[name='height']~span {
                    font-size: 12px;
                    line-height: 12px;
                    color: rgba(255, 255, 255, 0.5);
                }

                .sex {
                    display: inline-block;
                    opacity: 0.5;
                    width: 40px;
                    height: 24px;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    margin: 0 20px;
                    border-radius: 4px;
                    border: 1px solid rgba(255, 255, 255, 1);

                    &.is-active {
                        opacity: 1;
                    }
                }

                .get-code-btn {
                    min-width: fit-content;
                    font-size: 12px;
                    color: #fff;

                    &.is-disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .footer {

        // margin-top: 34px;
        .rule {
            color: #fff;
            margin: 16px auto;
            padding: 0 10px;
            height: auto;
            font-size: 12px;
            display: flex;
            align-items: center;

            // justify-content: center;
            i {
                font-style: normal;
            }

            .checkbox {
                position: relative;
                height: 14px;
                width: 14px;
                border-radius: 50%;
                margin-right: 5px;
                border: 1px solid #fff;

                input {
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    opacity: 0;
                }

                &.is-check {
                    &:before {
                        position: relative;
                        content: '';
                        height: 14px;
                        width: 14px;
                        margin: -1px;
                        border-radius: 50%;
                        // background: #00a4e6;
                        display: block;
                        overflow: hidden;
                        background: url('../../assets/images/comp_rb.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

            span {
                color: #4a90e2;
            }
        }

        .opacity {
            opacity: 0;
        }

        // 提交按钮
        .submit-btn {
            position: relative;
            margin-top: 32px;

            .error-tips {
                position: absolute;
                top: -25px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 12px;
                color: #fe6363;
            }

            .create-account {
                text-align: center;
                font-size: 14px;
                color: #4a90e2;
                margin-top: 16px;
            }

            button {
                width: 200px;
                height: 40px;
                outline: none;
                border-radius: 6px;
                background-image: -webkit-gradient(linear, left top, right top, from(#00E3C9), to(#009FE8));
                background-image: -webkit-linear-gradient(left, #00E3C9, #009FE8);
                background-image: -o-linear-gradient(left, #00E3C9, #009FE8);
                background-image: linear-gradient(to right, #00E3C9, #009FE8);
                font-size: 16px;
                color: #fff;

                &.is-disabled {
                    border: 1px solid transparent;
                    background-image: linear-gradient(#191b31, #191b31),
                        /* 底色，即原有的背景 */
                        linear-gradient(to right, #06b0a2, #067eb9);
                    /* 模拟渐变边框 */
                    background-clip: padding-box, border-box;
                    background-origin: border-box;
                }

                .spinnerBtn {
                    display: flex;
                    justify-content: center;
                }

                /deep/ .mint-spinner-snake {
                    border: 2px solid transparent;
                }

                &.custom-btn {
                    width: 345px;
                    height: 40px;
                    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                    border-radius: 6px;
                    margin-top: 20px;
                }
            }
        }
    }

    .icon-del {
        float: right;
        width: 9.5px;
        height: 9.5px;
        border-radius: 0.8px;
        background: url('../../assets/images/register/register_phone_delete.png') no-repeat;
    }

    .tips {
        position: relative;
        display: inline-block;
        font-size: 12px;
        height: 12px;
        line-height: 12px;
        min-width: 10px;
        color: #ffffff;
        margin-bottom: 30px;

        &::before {
            content: '*';
            position: absolute;
            width: 5px;
            left: -8px;
            top: 2px;
        }
    }

    //  /deep/ .item:nth-child(2){
    //      .info{
    //         .van-field__control{
    //             margin-left: 50px;
    //         }
    //      }  
    //     }

    &.ja-JP {
        .header p {
            width: 225px;
            line-height: 17px;
        }

        .header {
            h2 {
                width: 168px;
            }
        }

        /deep/ .content .form .item {
            .get-code-btn {
                min-width: 60px;
                text-align: left;
            }
        }

        /deep/ .item:nth-child(2) {
            .van-field__control {
                margin-left: 22px;
            }
        }

        /deep/ input[name='verifyCode'] {
            width: 104px;
            line-height: 20px;
            white-space: normal;
            word-break: break-all;
            word-wrap: break-word;
        }

        div .error-tips {
            width: 314px;
            height: 34px;
        }

        /deep/ .van-cell__value {
            margin-left: 0px;
            text-align: left;
        }

        .custom-btn {
            margin-top: 10px !important;
        }
    }

    &.en-US,
    &.it-IT,
    &.fr-FR,
    &.ru-RU,
    &.es-ES,
    &.pt-PT,
    &.tr-TR,
    &.de-DE {
        .header p {
            width: 312px;
            line-height: 17px;
        }

        .checkbox {
            border: 1px solid #8AA3BE !important;
        }

        i {
            color: #8AA3BE;
        }

        .submit-btn {
            .error-tips {
                width: 300px;
            }
        }
    }
 
    &.es-ES,
    &.pt-PT{
        .login {
            width: 222px;
        }

        .codeLabel {
            width: 70px;
        }
    }
    &.pt-PTO{
        .codeLabel-item{
            width: 80px;
        }
    }
    &.ar-AR{
        direction: rtl;
        .iteminput{
            direction: ltr;
        }
        .itemyzm{
            direction: ltr;
            text-align: right;
        }
        .header{
            p{
                text-align: right;
            }
        }
        .search{
            padding-right: 10px;
        }
         /deep/.el-dropdown{
        margin-left: 20px;
    }
    /deep/.van-field__control{
        text-align: right;
    }
    .content .form .item input,.footer .rule i,.content .form .item .area .arrow_bottom{
        margin-right: 10px;
    }
    .item .info .van-field__body{
        direction: ltr;
    }
    }
}
</style>
<style>
/deep/.register-page {
    background-image: linear-gradient(180deg, #262849, #07090f)!important;
}
input[type=number] {
    -moz-appearance: textfield;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #303C5C inset;
    -webkit-text-fill-color: #fff;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    -webkit-text-fill-color: #303C5C;
    transition: background-color 5000s ease-out 0.5s;
}

::-webkit-input-placeholder {
    color: #6c6c6f
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #6c6c6f
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #6c6c6f
}

:-moz-placeholder {
    color: #6c6c6f;
}   
</style>
